export const settings = {
  apiURL: "https://java-be-library-386523621546.herokuapp.com",
  siteName: "Library Dreams",
  siteUrl: "https://www.librarydreams.com/",
  imageServer: "https://book-loan-center.herokuapp.com",
  email: "info@librarydreams.com",
  phone1: "(609)-203-0997",
  phone2: "(609)-218-9730",
  address: "220 Montgomery St, San Francisco, CA 94104, USA",
  description:
    "JV library provides loaning hundreds of books with only one click",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.946105920924!2d-122.40454508449535!3d37.79130277975645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858089fba01fc1%3A0x9f46429b288092e3!2s220%20Montgomery%20St%2C%20San%20Francisco%2C%20CA%2094104%2C%20USA!5e0!3m2!1sen!2str!4v1670326792812!5m2!1sen!2str",
};



